<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      spinner="bar-fade-scale"
      text="Carregando Negociação"
    />

    <b-row>
      <b-col
        cols="12"
        xl="5"
      >

        <b-card no-body>

          <b-card-body>

            <b-badge
              :variant="negociacaoStatusCor(negociacaoInfo.statusNegociacaoValor)"
              class="badge-glow mb-1"
            >
              {{ negociacaoStatusTexto(negociacaoInfo.statusNegociacaoValor) }}
            </b-badge>

            <div class="row">
              <div class="col-md-6">
                <h6>Proposta de {{tipoNegociacaoTexto(negociacaoInfo.tipoNegociacaoValor)}}</h6>
                <h3 class="font-weight-bolder mb-1">
                  {{ negociacaoInfo.valorInicialProposta | currency }}
                </h3>
             </div>

              <div class="col-md-6">
                <h6>Proposta Atual <span  v-if="tipoUsuarioLogado == '1'">*</span></h6>
                <h3 class="font-weight-bolder mb-1">
                  {{ negociacaoInfo.valorUltimaProposta | currency }}
                </h3>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6>{{tipoNegociacaoTexto(negociacaoInfo.tipoNegociacaoValor)}} inicial</h6>
                <h3 class="font-weight-bolder mb-1">
                  {{negociacaoInfo.tempoInicialPropostaDescricao}}
                </h3>
             </div>

              <div class="col-md-6">
                <h6>{{tipoNegociacaoTexto(negociacaoInfo.tipoNegociacaoValor)}}  Atual </h6>
                <h3 class="font-weight-bolder mb-1">
                  {{negociacaoInfo.tempoUltimaPropostaDescricao}}
                </h3>
              </div>
            </div>
      
            <b-button
              variant="dark"
              size="sm"
              style="cursor: default"
              class="mb-1"
            >
            {{tipoNegociacaoTexto(negociacaoInfo.tipoNegociacaoValor)}}
            </b-button>

            <div v-if="usuarioUltimaProposta != tipoUsuarioLogado">
              <b-button
                v-if="negociacaoInfo.statusNegociacaoValor == '0'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary bg-gradient-primary"
                block
                :disabled="respostaApi"
                @click="aceitarProposta(negociacaoInfo.id)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-50"
                />
                Aceitar
                <b-spinner
                  v-if="respostaApi"
                  small
                  label="Carregando"
                />
              </b-button>

              <b-button
                v-if="negociacaoInfo.statusNegociacaoValor == '0'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="contraProposta(negociacaoInfo.id)"
              >
                Enviar contraproposta
              </b-button>

              <b-button
                v-if="negociacaoInfo.statusNegociacaoValor == '0'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                block
                @click="recusarProposta(negociacaoInfo.id)"
              >

                Recusar
              </b-button>
            </div>
            <div v-if="usuarioUltimaProposta == tipoUsuarioLogado && negociacaoInfo.statusNegociacaoValor == '0'">
              <b-alert
                variant="primary"
                show
                class="mt-1"
              >
                <div class="alert-body">
                  <span>{{ negociacaoAguardar(negociacaoInfo.statusNegociacaoValor) }}</span>
                </div>
              </b-alert>
            </div>
            <div v-if="negociacaoInfo.statusNegociacaoValor == '1' || negociacaoInfo.statusNegociacaoValor == '2'">
              <b-alert
                variant="success"
                show
                class="mt-1"
              >
                <div class="alert-body">
                  <span>Um consultor do Mercado Hit entrará em contato com você para dar prosseguimento a negociação!</span>
                </div>
              </b-alert>
            </div>
            <div v-if="tipoUsuarioLogado == '1'">
              <b-alert variant="warning" show class="mt-1">
                <div class="alert-body">
                  <span>ATENÇÃO: O mercado hit receberá 10% sobre a venda deste hit.</span>
                </div>
              </b-alert>
            </div>
            
          </b-card-body>



        </b-card>
          
        <div v-if="negociacaoInfo.statusNegociacaoValor == '7'">
          <b-card class="pr-2">
            <b-row>
              <b-col cols="6" sm="10">
                <audio-player
                  ref="audioPlayer"
                  :audio-list="[negociacaoInfo.hitArquivoLocalArquivo]"
                  :show-playback-rate="false"
                  :show-volume-button="false"
                  :show-next-button="false"
                  :show-prev-button="false"
                  class="w-100 custom-audio-player"
                />
              </b-col>
          
              <b-col cols="4" sm="1">
                <b-button variant="primary" @click="downloadHit()" class="custom-button">
                  <feather-icon v-if="!carregandoDownload" icon="DownloadIcon" size="18"/>
                  <span v-if="carregandoDownload" class="ml-1 position-absolute" style="right: 20%; top: 0%; transform: translate(-20%, -0%);">
                    <vue-element-loading spinner="bar-fade-scale" color="#FFF" :active="carregandoDownload" class="custom-spinner"
                    />
                  </span>
                </b-button>
              </b-col>
              <b-col cols="2" sm="1">
              <b-button variant="dark" @click="togglePDF()" class="custom-button" style="margin-left: 1px; ">
                <feather-icon icon="FileTextIcon" size="18"/>
              </b-button>
            </b-col>
            </b-row>
          </b-card>
        </div>
  
            <b-modal id="pdf-modal" ref="pdfModal" size="lg" title="Visualizar PDF" hide-footer>
              <iframe :src="pdfSrc" style="width:100%; height:80vh;" frameborder="0"></iframe>
            </b-modal>
      </b-col>

      <b-col
        cols="12"
        xl="7"
      >

        <b-card :title="negociacaoInfo.hitNomeComposicao">
          <div v-if="tipoUsuarioLogado == '1' && negociacaoInfo.upgrade == true">
            <b-alert variant="warning" show class="mt-1">
              <div class="alert-body">
                <span>Este comprador adquiriu a liberação da composição por {{negociacaoInfo.valorVendaAnterior | currency}} e agora deseja adquirir a exclusividade.</span>
              </div>
            </b-alert>
          </div>
          <negociacao-timeline>

            <negociacao-timeline-item
              v-for="negociacao in negociacoes"
              :title="tituloTexto(negociacao.statusNegociacaoValor, negociacao)"
              :subtitle="negociacaoTexto(negociacao.tipoPropostaValor, negociacao.statusNegociacaoValor)"
              :time="negociacao.data"
              :variant="negociacaoCor(negociacao.tipoPropostaValor, negociacao.statusNegociacaoValor)"
            />
          </negociacao-timeline>

        </b-card>

      </b-col>

    </b-row>

    <!-- Modal de Negociação -->

    <b-modal
      id="modal-negociar"
      ref="modalNegociar"
      ok-only
      ok-title="Enviar Contraproposta"
      centered
      no-close-on-backdrop
      size="sm"
      title="Contraproposta"
      @ok="negociarHit"
    >

      <template v-slot:modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="invalid || respostaApi"
          @click="negociarHit"
        >
          Enviar Contraproposta
          <b-spinner
            v-if="respostaApi"
            small
            label="Carregando"
          />
        </b-button>
      </template>

      <b-row class="mt-2">

        <b-col>
          <!-- media -->
          <b-media
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                Proposta Atual
              </h6>
              <small>{{ negociacaoInfo.valorUltimaProposta | currency }}</small>
            </b-media-body>
          </b-media>
          
        </b-col>
        
      </b-row>

  

      <validation-observer
        #default="{invalid}"
        ref="negociacaoForm"
      >

        <!-- Form -->
        <b-form
          ref="negociacaoForms"
          class="mt-2"
          @submit.prevent="negociarHitCadastrar"
        >

          <b-row>

            <b-col>

              <!-- Estilos -->
              <b-form-group
                label="Valor"
                label-for="hitValorPropostaLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitValorPropostaLabel"
                >

                  <money
                    v-model="hitValorProposta"
                    v-bind="money"
                    class="form-control lg"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estilos -->

            </b-col>

          </b-row>

          <b-row class="mt-2">
          
            <b-col>

              <!-- Tempo -->
              <b-form-group
                label="Tempo de venda"
                label-for="hitTempoVenda"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTempoVenda"
                >

                  <v-select
                    v-model="tempoVendaSelecionada"
                    label="title"
                    :options="tempoVenda"
                  />


                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Tempo -->

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

  </div>

</template>

<script>

import Vue from 'vue'

import {
 BAlert, BModal, BSpinner, BRow, BCol, BCard, BCardTitle, BCardHeader, BFormInvalidFeedback, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, RippleDirective,
} from 'bootstrap-vue'

import router from '@/router'

import vSelect from 'vue-select'

import AudioPlayer from '@liripeng/vue-audio-player'


import VueCurrencyFilter from 'vue-currency-filter'

import useJwt from '@/auth/jwt/useJwt'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import { Money } from 'v-money'
import NegociacaoTimelineItem from './NegociacaoTimelineItem.vue'
import NegociacaoTimeline from './NegociacaoTimeline.vue'

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})

export default {
  directives: {
    Ripple: RippleDirective,
  },
  components: {
    AudioPlayer,
    BAlert,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueElementLoading,
    NegociacaoTimeline,
    NegociacaoTimelineItem,
    VueCurrencyFilter,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    BFormInvalidFeedback,

    Money,
    vSelect,

  },
  data() {
    return {
      negociacaoInfo: {},
      negociacoes: {},
      carregamentoApi: false,
      carregandoDownload: false,
      respostaApi: false,
      pdfSrc: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },

      hitValorProposta: 0,
      propostaId: router.currentRoute.params.id,
      tipoUsuarioLogado: '',
      usuarioUltimaProposta: '',

      tempoVendaSelecionada: {id: 0, title: '12 meses' },
      tempoVenda: [
        { id: 0, title: '12 meses' },
        { id: 1, title: '18 meses' },
        { id: 2, title: '24 meses' },
      ],


    }
  },
  mounted() {
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    this.tipoUsuarioLogado = usuarioData.authority == 'ROLE_COMPOSITOR'  ? '1' : '0';
  },
  created() {
    this.negociacoesLista()
  },

  methods: {
    negociacaoCor(tipoProposta, statusNegociacao) {

      if (statusNegociacao == '1' || statusNegociacao == '2') return 'success'
      if (statusNegociacao == '3' || statusNegociacao == '4') return 'danger'
      if (statusNegociacao == '7') return 'success'

      if (tipoProposta == '0') return 'primary'
      if (tipoProposta == '1') return 'secondary'
      
      return 'warning'
    },

    tituloTexto(statusNegociacao, negociacao) {
      if(statusNegociacao == '0') {
        var valorFormatado = 'R$ ' + negociacao.valor.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return valorFormatado;
      } 
      if(statusNegociacao == '1' || statusNegociacao == '2' || statusNegociacao == '3' || statusNegociacao == '4'){
        return this.negociacaoEncerradaTexto(negociacao.tipoPropostaValor, negociacao.statusNegociacaoValor)
      }
      if(statusNegociacao == '5') return 'Aguardando documentação do compositor'
      if(statusNegociacao == '6') return 'Aguardando pagamento do comprador'
      if(statusNegociacao == '7') return 'A venda do hit foi concluída'
      
      
      return 'Erro'
    },

    negociacaoTexto(tipoProposta, statusNegociacao) {

      if(statusNegociacao == '0') {
        if (tipoProposta == '0' && this.tipoUsuarioLogado == '1') return 'O comprador fez uma nova proposta'
        if (tipoProposta == '1' && this.tipoUsuarioLogado == '1') return 'Você fez uma contraproposta'
        if (tipoProposta == '0' && this.tipoUsuarioLogado == '0') return 'Você fez uma nova proposta'
        if (tipoProposta == '1' && this.tipoUsuarioLogado == '0') return 'O compositor fez uma contraproposta'
       
      }
      if(statusNegociacao == '1' || statusNegociacao == '2' || statusNegociacao == '3' || statusNegociacao == '4'|| 
          statusNegociacao == '5'|| statusNegociacao == '6'|| statusNegociacao == '7') {
        return ''
      }
      return 'Erro'
    },

    tipoNegociacaoTexto(tipoNegociacao) {
      if (tipoNegociacao == '0') return 'Liberação'
      if (tipoNegociacao == '1') return 'Exclusividade'
      return 'Erro'
    },

    negociacaoEncerradaTexto(tipoProposta, statusNegociacao) {
      
      const msg1 = 'Você aceitou a proposta do compositor';
      const msg2 = 'O comprador aceitou sua proposta';
      const msg3 = 'O compositor aceitou sua proposta';
      const msg4 = 'Você aceitou a proposta do comprador';
      const msg5 = 'Você recusou a proposta do compositor';
      const msg6 = 'O comprador recusou sua proposta';
      const msg7 = 'O compositor recusou sua proposta';
      const msg8 = 'Você recusou a proposta do comprador';

      if (tipoProposta == '0' && this.tipoUsuarioLogado == '0' && statusNegociacao == '1') return msg1
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '1' && statusNegociacao == '1') return msg2
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '0' && statusNegociacao == '2') return msg3
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '1' && statusNegociacao == '2') return msg4
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '0' && statusNegociacao == '3') return msg5
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '1' && statusNegociacao == '3') return msg6
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '0' && statusNegociacao == '4') return msg7
      if (tipoProposta == '0' && this.tipoUsuarioLogado == '1' && statusNegociacao == '4') return msg8

      if (tipoProposta == '1' && this.tipoUsuarioLogado == '0' && statusNegociacao == '1') return msg1
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '1' && statusNegociacao == '1') return msg2
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '0' && statusNegociacao == '2') return msg3
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '1' && statusNegociacao == '2') return msg4
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '0' && statusNegociacao == '3') return msg5
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '1' && statusNegociacao == '3') return msg6
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '0' && statusNegociacao == '4') return msg7
      if (tipoProposta == '1' && this.tipoUsuarioLogado == '1' && statusNegociacao == '4') return msg8
      
      return 'Finalizada ' + tipoProposta + this.tipoUsuarioLogado + statusNegociacao

    },

    negociacaoAguardar(statusNegociacao) {
      const msgComprador = 'Aguarde enquanto o comprador analisa sua proposta.';
      const msgCompositor = 'Aguarde enquanto o compositor analisa sua proposta.';
      if (statusNegociacao == '0' && this.tipoUsuarioLogado == '1') return msgComprador
      if (statusNegociacao == '1' && this.tipoUsuarioLogado == '1') return msgComprador
      if (statusNegociacao == '0' && this.tipoUsuarioLogado == '0') return msgCompositor
      if (statusNegociacao == '1' && this.tipoUsuarioLogado == '0') return msgCompositor
      return 'Erro'
    },

    negociacaoStatusCor(statusNegociacao) {
      if (statusNegociacao == '0') return 'secondary'
      if (statusNegociacao == '1' || statusNegociacao == '2') return 'warning'
      if (statusNegociacao == '3' || statusNegociacao == '4') return 'danger'
      if (statusNegociacao == '5') return 'warning'
      if (statusNegociacao == '6') return 'warning'
      if (statusNegociacao == '7') return 'success'
      if (statusNegociacao == '8') return 'danger'
      return 'secondary'
    },

    negociacaoStatusTexto(statusNegociacao) {
      if (statusNegociacao == '0') return 'Em negociação'
      if (statusNegociacao == '1' || statusNegociacao == '2') return 'Aguardando'
      if (statusNegociacao == '3' || statusNegociacao == '4') return 'Recusada'
      if (statusNegociacao == '5') return 'Aguardando documento'
      if (statusNegociacao == '6') return 'Aguardando pagamento'
      if (statusNegociacao == '7') return 'Concluída'
      if (statusNegociacao == '8') return 'Cancelada'
      return 'Em negociação'
    },

    aceitarProposta(propostaId) {
      this.$swal({
        title: 'Deseja aceitar a proposta?',
        text: 'Ao clicar em aceitar, um consultor do Mercado Hit entrará em contato com você.',
        icon: 'question',
        iconColor: '#ff7300',
        showCancelButton: true,
        confirmButtonText: 'Sim, aceitar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // API

          this.respostaApi = true

          useJwt.negociacaoPropostaFinalizar({
            propostaId,
            aceita: true,
          })
            .then(response => {
              this.$swal({
                title: 'Proposta Aceita!',
                text: 'Você aceitou a proposta! Um consultor do Mercado Hit entrará em contato com você.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.go()
                } else {
                  this.$router.go()
                }
              })
            })
            .catch(error => {
              // this.hits = '';
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.respostaApi = false
            })

          // API
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },

    contraProposta(propostaId) {
      this.$refs.modalNegociar.show()
    },

    recusarProposta(propostaId) {
      this.$swal({
        title: 'Deseja recusar a proposta?',
        text: 'Ao recusar uma proposta você finalizará a negociação.',
        textColor: '#ff7300',
        icon: 'question',
        iconColor: '#ff7300',
        showCancelButton: true,
        confirmButtonText: 'Sim, recusar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // API

          this.respostaApi = true

          useJwt.negociacaoPropostaFinalizar({
            propostaId,
            aceita: false,
          })
            .then(response => {
              this.$swal({
                title: 'Proposta Recusada!',
                text: 'Você recusou a proposta, e essa negociação foi encerrada.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.go()
                } else {
                  this.$router.go()
                }
              })
            })
            .catch(error => {
              // this.hits = '';
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.respostaApi = false
            })

          // API
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },

    // INSERIR NEGOCIAÇÃO

    negociarHit() {
      this.$refs.negociacaoForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          useJwt.negociacaoPropostaNegociar({
            propostaId: this.propostaId,
            valor: this.hitValorProposta,
            tempoVenda: this.tempoVendaSelecionada.id,
          })
            .then(response => {
              this.$swal({
                title: 'Contraproposta Enviada!',
                text: 'A sua contraproposta foi enviada, acompanhe o histórico dessa negociação.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,

              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.go()
                } else {
                  this.$router.go()
                }
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Atenção!',
                text: error.response.data.message,
                icon: 'warning',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  this.$refs.modalNegociar.hide()
                } else {
                  this.$refs.modalNegociar.hide()
                }
              })
            })
            .finally(() => {
              this.respostaApi = false
            })
        }
      })
    },   

    negociacoesLista() {
      this.carregamentoApi = true

      useJwt.negociacoesLista({
        propostaId: router.currentRoute.params.id,
      })
        .then(response => {
          
          const negociacaoInfo = response.data.content
          this.negociacaoInfo = negociacaoInfo[0]


          this.negociacoes = negociacaoInfo[0].historicoProposta
        
          const ultimaProposta = this.negociacoes.slice(-1);
          this.usuarioUltimaProposta = ultimaProposta[0].tipoPropostaValor;

          this.pdfSrc = this.negociacaoInfo.arquivoLocalArquivo;
          if(this.pdfSrc){
            this.showPdf = true;
          }
          // console.log(negociacaoInfo[0].historicoProposta);
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    downloadHit() {
      this.carregandoDownload = true
      useJwt.downloadHit({
        hitId: this.negociacaoInfo.hitId,
      })
      .then(response => {
        const extensao = this.negociacaoInfo.hitArquivoLocalArquivo.split('.').pop()
        const nomeHit = `${this.negociacaoInfo.hitNomeComposicao}.${extensao}`

        const a = window.document.createElement('a')
        a.href = "data:audio/mp3; base64, " + response.data.file
        a.download = nomeHit;

        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      })
      .catch(error => {
        console.error('Erro:', error)
      })
      .finally(() => {
        this.carregandoDownload = false
      })
    },
    togglePDF() {
        if (this.pdfSrc) {
          this.$refs.pdfModal.show()
        } else {
          this.$swal({
            title: 'Nenhum PDF encontrado',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }
  },
}
</script>

<style>
  .custom-button {
    height: 30px;
    width: 40px;
    padding: 5px;
    margin-left: -10px;
  }

  .custom-button .feather-icon {
    width: 14px;    
    height: 14px;   
  }

  .custom-audio-player svg.audio__play-icon {
    margin-left: -25px;
    margin-right: -5px;
    width: 30px;
    height: 30px;
  }

  .audio-player {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .audio-player .audio__progress-wrap {
    margin-top: 0px;
  }

  .audio__btn-wrap {
    flex: 0 0 auto; /* para impedir que o elemento se expanda */
    margin-right: 10px; /* espaço entre o ícone e a barra de progresso */
  }

  .audio__progress-wrap {
    flex: 1 1 auto; /* para que a barra de progresso ocupe o restante do espaço disponível */
  }

  .audio-player .audio__time-wrap {
    display: none;
  }

  .audio-player .audio__play-start {
    color: #727272 !important;
  }

  .audio-player .audio__play-pause {
    color: #727272 !important;
  }

  .audio-player .audio__progress-wrap .audio__progress {
    background-color: #ff9f43 !important;
  }

  .audio-player .audio__progress-wrap .audio__progress-point {
    background-color: #ff9f43 !important;
  }

  .pdf-container {
    width: 100%;
    height: 100%; 
  }
  
  .pdf-viewer {
    width: 100%; 
    height: 50vh; 
  }

  @media (max-width: 767.98px) {
    .custom-button {
      height: 30px;
      width: 40px;
      padding: 5px;
      margin-left: 55px;
    }
  
    .custom-audio-player {
      width: 140% !important;
    }
  
    .custom-audio-player svg.audio__play-icon {
      margin-left: -25px;
      margin-right: -5px;
      width: 30px;
      height: 30px;
    }
  
    .audio__btn-wrap {
      flex: 0 0 auto; /* para impedir que o elemento se expanda */
      margin-right: 10px; /* espaço entre o ícone e a barra de progresso */
    }
  
    .audio__progress-wrap {
      margin-right: -10px;
    }
  }

</style>
